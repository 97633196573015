import { Link } from 'gatsby'
import React from 'react'
import style from './style.module.scss'

class Footer extends React.Component{

  _isMounted = false;

  state = {
    isTop: false,
  };

  constructor() {
    super();
  }

  componentDidMount() {

    this._isMounted = true;

    if(this._isMounted) {

      const elem = this

      setTimeout(function(){
        let pageHeight = document.body.clientHeight;
        if (pageHeight === window.innerHeight) {
          elem.setState({isTop: true});
        }

        document.addEventListener('scroll', () => {
          if (window.scrollY >= pageHeight - window.innerHeight) {
            elem.setState({isTop: true})
          } else {
            elem.setState({isTop: false})
          }
        });
      },100)
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return(
      <div className={style.footer}>
        <span className={( (this._isMounted ? this.state.isTop : false) ? 'active' : '' )}>© 2020 - Dinamiza, un marchio STARSOFTWARE - P. IVA 03352380277 cap.soc.€ 50.000 i.v. ~ <a href={'https://www.dinamiza.it/informative-privacy/'}>Informative policies</a></span>
      </div>
    )
  }
}

export default Footer
