import React from 'react'
import Button from 'components/button'
import style from './style.module.scss'

class NaviBottom extends React.Component {
  render() {
    return (
      <div className={style.navbar__bottom}>
        <div className={style.navbar__bottom__content}>
          <div className={style.navbar__bottom__content__social__container}>
            <ul>
              <li data-hover>
                <a
                  href="https://it.linkedin.com/company/dinamiza-web-marketing-agency"
                  target={'_blank'}
                >
                  <i className="fa fa-linkedin" aria-hidden="true"></i>
                </a>
              </li>
              <li data-hover>
                <a href="https://www.facebook.com/dinamiza" target={'_blank'}>
                  <i className="fa fa-facebook-square" aria-hidden="true"></i>
                </a>
              </li>
            </ul>
          </div>

          <div
            data-hover
            className={`button-talk ${style.navbar__bottom__content__btn}`}
          >
            <Button href={'/contatti'}>Contattaci</Button>
          </div>
        </div>
      </div>
    )
  }
}

export default NaviBottom
