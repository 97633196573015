import React from 'react'
import { Link } from 'gatsby'
import Navbar from 'react-bootstrap/Navbar'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import style from './style.module.scss'

class Navi extends React.Component {

  render() {
    const { location, title } = this.props
    return (
      <div>
        <nav
          className={`navbar navbar-expand navbar-dark flex-column flex-md-row fixed-top ${style.navbar}`}
        >
          <div className={'container-fluid'}>
            <div className={style.navbar__logoContainer} data-hover>
              <AniLink className="text-center" cover direction="up" bg={'#2E2E2F'} to="/">
                <img
                  className={style.navbar__logo}
                  src={'/img/logo-navbar.svg'}
                  alt={title}
                  data-hover
                />
                <h1>Dinamiza</h1>
              </AniLink>
            </div>
          </div>
        </nav>
        <Navbar collapseOnSelect expand="lg" className={`navbar navbar-expand-lg navbar-dark flex-column flex-md-row fixed-top ${style.navbar} ${style.navbar__navbar_link}`}>
          <div className="container-fluid">
            <div className={style.navbar__logoContainer}>
              <AniLink
                className="text-center"
                style={{ display: 'block', height: '71px' }}
                cover direction="up" bg={'#2E2E2F'}
                to="/"
              >
                <img
                  style={{ visibility: 'hidden' }}
                  src={'/img/logo-navbar.svg'}
                  alt={title}
                />
              </AniLink>
            </div>


            <Navbar.Toggle aria-controls="navbarSupportedContent">
              <span className="icon-bar top-bar"></span>
              <span className="icon-bar middle-bar"></span>
              <span className="icon-bar bottom-bar"></span>
            </Navbar.Toggle>

            <Navbar.Collapse className={`collapse navbar-collapse navbar-nav-scroll ${style.navbar__menu}`} id="navbarSupportedContent">
              <ul className="navbar-nav bd-navbar-nav">
                <li
                  data-hover
                  className={
                    location.pathname === '/' ? 'nav-item active' : 'nav-item'
                  }
                >
                  <AniLink to="/" cover direction="up" bg={'#2E2E2F'} className="nav-link">
                    Home
                  </AniLink>
                </li>
                <li
                  data-hover
                  className={
                    location.pathname === '/chi-siamo/'
                      ? 'nav-item active'
                      : 'nav-item'
                  }
                >
                  <AniLink to="/chi-siamo/" cover direction="up" bg={'#2E2E2F'} className="nav-link">
                    Chi Siamo
                  </AniLink>
                </li>
                <li
                  data-hover
                  className={
                    location.pathname === '/projects/'
                      ? 'nav-item active'
                      : 'nav-item'
                  }
                >
                  <AniLink to="/projects/" cover direction="up" bg={'#2E2E2F'} className="nav-link">
                    Progetti
                  </AniLink>
                </li>
                <li
                  data-hover
                  className={
                    location.pathname === '/servizi/'
                      ? 'nav-item active'
                      : 'nav-item'
                  }
                >
                  <AniLink to="/servizi/" cover direction="up" bg={'#2E2E2F'} className="nav-link">
                    Servizi
                  </AniLink>
                </li>
                <li
                  data-hover
                  className={
                    location.pathname === '/lavora-con-noi/'
                      ? 'nav-item active'
                      : 'nav-item'
                  }
                >
                  <AniLink to="/lavora-con-noi/" cover direction="up" bg={'#2E2E2F'} className="nav-link">
                    Lavora con noi
                  </AniLink>
                </li>
                <li
                  data-hover
                  className={
                    location.pathname === '/contact/'
                      ? 'nav-item active'
                      : 'nav-item'
                  }
                >
                  <AniLink to="/contatti/" cover direction="up" bg={'#2E2E2F'} className="nav-link">
                    Contattaci
                  </AniLink>
                </li>
              </ul>
            </Navbar.Collapse>

          </div>
        </Navbar>
      </div>
    )
  }
}

export default Navi
