import React from 'react'
import Helmet from 'react-helmet'
import get from 'lodash/get'

const Meta = ({ site, title, description }) => {
  const siteTitle = get(site, 'title')
  const siteDescription = get(site, 'description')
  const siteKeywords = get(site, 'keywords')
  title = title
    ? `${title} | ${siteTitle} - ${siteDescription}`
    : `${siteTitle} - ${siteDescription}`
  description = description ? `${description}` : siteDescription
  return (
    <Helmet
      title={title}
      meta={[
        { name: 'description', content: description },
        { name: 'keywords', content: siteKeywords },
        { name: 'twitter:card', content: 'summary' },
        {
          name: 'twitter:site',
          content: `@${get(site, 'twitter')}`,
        },
        { property: 'og:title', content: title },
        { property: 'og:type', content: 'website' },
        {
          property: 'og:description',
          content: description,
        },
        {
          property: 'og:url',
          content: `${get(site, 'siteUrl')}/profile`,
        },
        {
          property: 'og:image',
          content: `${get(site, 'siteUrl')}/img/profile.jpg`,
        },
      ]}
    />
  )
}
export default Meta
