import style from 'scss/global.scss'
import React from 'react'
import emergence from 'emergence.js'
import Navi from 'components/navi'
import Footer from 'components/footer'
import { siteMetadata } from '../../../gatsby-config'
import NaviBottom from 'components/naviBottom'
import Smooth from './smooth'
import { gsap } from 'gsap'
//import PageTransition from './page-transition.scss'

import styleLayout from './style.module.scss'
import layoutStyle from 'scss/components/layout.module.scss'
//import Cursor from "components/cursor";

class Layout extends React.Component {
  initFunctions() {
    emergence.init()
    //new Smooth()
  }

  componentDidMount() {
    this.initFunctions()
  }
  componentDidUpdate() {
    this.initFunctions()
  }

  render() {
    const { children } = this.props
    return (
      <div className={layoutStyle.layout}>
        <Navi title={siteMetadata.title} {...this.props} />
        <div data-scroll>
          <div data-scroll-content>{children}</div>
        </div>
        <NaviBottom />
        <Footer title={siteMetadata.title} author={siteMetadata.author} />
      </div>
    )
  }
}

export default Layout
