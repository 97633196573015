import React from 'react'
import style from './style.module.scss'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

class Button extends React.Component {
  render() {
    const { href, children } = this.props

    return (
      <AniLink
        className={style.btnCustom}
        to={href ? href : ''}
        cover
        direction="up"
        bg={'#2E2E2F'}
      >
        {children}
      </AniLink>
    )
  }
}

export default Button
