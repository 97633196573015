module.exports = {
  siteMetadata: {
    title: "Dinamiza",
    description: "Web Agency Venezia",
    keywords:
      "e-commerce,  siti internet, automation, applicazioni, web agency",
    siteUrl: "https://dinamiza.it",
    author: "filippobenozzi",
    twitter: "",
    adsense: "",
  },
  pathPrefix: "/",
  plugins: [
    {
      resolve: "gatsby-source-filesystem",
      options: {
        path: `${__dirname}/content/posts/`,
        name: "posts",
      },
    },
    {
      resolve: "gatsby-source-filesystem",
      options: {
        path: `${__dirname}/content/images/`,
        name: "images",
      },
    },
    {
      resolve: "gatsby-transformer-remark",
      options: {
        plugins: [
          {
            resolve: "gatsby-remark-images",
            options: {
              maxWidth: 750,
              linkImagesToOriginal: false,
              wrapperStyle: "margin-bottom: 1.0725rem;",
            },
          },
          {
            resolve: "gatsby-remark-responsive-iframe",
            options: {
              wrapperStyle: "margin-bottom: 1.0725rem",
            },
          },
          "gatsby-remark-prismjs",
          "gatsby-remark-copy-linked-files",
          "gatsby-remark-smartypants",
        ],
      },
    },
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: "Dinamiza",
        short_name: "Dinamiza",
        description: "Digital Agency",
        homepage_url: "https://dinamiza.it",
        start_url: "/",
        background_color: "#fff",
        theme_color: "#ee3537",
        display: "standalone",
        icons: [
          {
            src: "/img/favicon-32.png",
            sizes: "32x32",
            type: "image/png",
          },
          {
            src: "/img/favicon-64.png",
            sizes: "64x64",
            type: "image/png",
          },
        ],
      },
    },
    {
      resolve: `gatsby-plugin-google-gtag`,
      options: {
        trackingIds: [
          "G-0FVQFVSS0L", // Google Analytics / GA
        ],
        pluginConfig: {
          head: true,
        },
      },
    },
    {
      resolve: "gatsby-plugin-netlify",
      options: {
        mergeSecurityHeaders: true,
        mergeLinkHeaders: true,
        mergeCachingHeaders: true,
      },
    },
    {
      resolve: "gatsby-plugin-iubenda-cookie-footer",
      options: {
        iubendaOptions: {
          whitelabel: false,
          lang: "it",
          siteId: 246715,
          cookiePolicyId: 280626,
          banner: {
            position: "float-bottom-center",
            textColor: "white",
            backgroundColor: "#141414",
          },
        },
      },
    },
    {
      resolve: "gatsby-plugin-react-svg",
      options: {
        rule: {
          include: /img\/.*\.svg/,
        },
      },
    },
    {
      resolve: `gatsby-plugin-hotjar-tracking`,
      options: {
        includeInDevelopment: false,
        id: 1982960,
        sv: 6,
      },
    },
    "gatsby-plugin-catch-links",
    "gatsby-plugin-offline",
    "gatsby-plugin-react-helmet",
    "gatsby-plugin-sass",
    "gatsby-plugin-sharp",
    "gatsby-plugin-sitemap",
    "gatsby-plugin-twitter",
    "gatsby-transformer-sharp",
    "gatsby-plugin-transition-link",
  ],
};
